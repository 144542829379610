<template>
  <div>
    <TopNav />

    <div class="row">
      <router-view />

    </div>


  </div>
</template>

<script>
import TopNav from './components/TopNav.vue';
export default {
  components: {
    TopNav
  }
};
</script>

<style scoped>
main {
  margin-top: 60px;
  /* Ensure content starts below the fixed navbar */
}
</style>
