import { createWebHistory, createRouter } from "vue-router";
import Login from '../pages/Login.vue';
import Register from '../pages/Register.vue';
import Home from '../pages/Home.vue';
import About from '../pages/About.vue';
import Promo from '../pages/Promo.vue';
import Menu from '../pages/Menu.vue';
import Table from '../pages/Table.vue';
import Cart from '../pages/Cart.vue';
import Checkout from '../pages/Checkout.vue';
import Thank from '../pages/Thank.vue';
import MyOrder from '../pages/MyOrder.vue';
import Admin from '../admin/Admin.vue';
import Dashboard from '../admin/Dashboard.vue';
import userProfile from '../pages/userProfile.vue';
import adminHome from '../admin/Home.vue';
import Orders from '../admin/components/Orders.vue';
import adminMenu from '../admin/components/Menu.vue';
import Settings from '../admin/components/Settings.vue';
import Details from '../admin/components/RestaurantDetails.vue';
import Driver from "../admin/components/Driver.vue";
import driverHome from "../driver/Home.vue"
import driverDashboard from "../driver/Dashboard.vue";
import driverProfile from "../driver/components/userProfile.vue"
import driverOders from "../driver/components/Orders.vue"
import NotFound from "../pages/NotFound.vue";
import restaurantRegisration from "../pages/restaurantRegisration.vue";
import FileUploading from "../components/FileUploading.vue"



const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path:"/upload",
    name: "upload",
    component: FileUploading
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: Promo,
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/table",
    name: "Table",
    component: Table,
  },
  {
    path: "/restaurantReg",
    name: "Registration",
    component: restaurantRegisration
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/thank",
    name: "Thank",
    component: Thank,
  },
  {
    path: "/myorder",
    name: "MyOrder",
    component: MyOrder,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/profile",
    name: "userProfile",
    component: userProfile
  },
  {
    path: "/admin/dashboard",
    component: Dashboard,
    children: [
      {
        path: "/admin/dashboard/",
        component: adminHome
      },
      {
        path: "/admin/orders",
        component: Orders
      },
      {
        path: "/admin/menu",
        component: adminMenu
      },
      {
        path: "/admin/setting",
        component: Settings
      },
      {
        path: "/admin/details",
        component: Details
      },
      {
        path: "/admin/driver",
        component: Driver
      },
      {
        path: '/admin/:pathMatch(.*)*',
        component: NotFound,
      }

    ]
  },
  {
    path: "/driver/home",
    component: driverDashboard,
    children: [
      {
        path: '/driver/home',
        component: driverHome
      },
      {
        path: '/driver/details',
        component: driverProfile
      },
      {
        path: '/driver/orders',
        component: driverOders
      },
      {
        path: '/drivers/:pathMatch(.*)*',
        component: NotFound,
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;