<template>


    <div class="admin-container">


        <div class="row">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Total Salles (Monthly)</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">Tsh 40,000</div>
                                <!-- must be qeried from the db -->
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold  text-success text-uppercase mb-1">
                                    Total Orders (weekly)</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ allBills.length }}</div>
                                <!-- number has to be fetched -->
                            </div>
                            <div class="col-auto">
                                <div class="fas fa-shopping-cart cart fa-2x"></div>
                                <!-- <i class="fas fa-shopping-cart cart"></i> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tasks Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Oder Completed
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">70%</div>
                                    </div>
                                    <div class="col">
                                        <!-- means to deal with progress -->
                                        <div class="progress progress-sm mr-2">
                                            <div class="progress-bar bg-info" role="progressbar" style="width: 70%"
                                                aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fa-solid fa-square-check fa-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pending Requests Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    Pending orders</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">09</div>
                                <!-- from db -->
                            </div>
                            <div class="col-auto">
                                <!-- <i class="fas fa-comments fa-2x text-gray-300"></i> -->
                                <i class="fa-solid fa-spinner fa-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <!-- PROJECT TABLE -->
            <table class="table colored-header datatable project-list">
                <thead class="thead-light" >
                    <tr>
                        <th>Bill Id</th>
                        <th>User Id</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>When</th>
                        <th>Paid</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(b) in filterBills.slice().reverse()" :key="b.bill_id">
                        <td> <button title="Preview Order" @click="sendBillId(b.bill_id)" class="action-btn">{{ b.bill_id }}</button> </td>
                        <td>{{ b.user_id }}</td>
                        <td>{{ b.bill_phone }}</td>
                        <td>{{ b.bill_address }}</td>
                        <td>{{ b.bill_when }}</td>
                        <td>{{ b.bill_paid }}</td>
                        <td>Tsh{{ b.bill_total }}</td>
                        <td>{{ avaiableStatus[b.bill_status] }}</td>
                        <td>
                            <button v-if="b.bill_status < 4" class="action-btn" @click="nextStatusBtn(b.bill_id)">
                                {{ avaiableStatus[b.bill_status + 1] }}
                            </button>

                            <driverSign v-if="showDriverSelect && b.bill_status == 3" :bill_id="b.bill_id">
                                <button class="btn" @click="closeDriverSelect">X</button>

                            </driverSign>

                            <button v-if="b.bill_status == 1" class="cancel-btn" @click="cancelBtn(b.bill_id)">
                                Cancel
                            </button>


                            <button v-else-if="b.bill_status == 5 && b.bill_paid != 'true'" class="paid-btn"
                                @click="paidBtn(b.bill_id)">
                                Paid
                            </button>

                            <button v-else-if="b.bill_status == 5 && b.bill_paid == 'true'" class="action-btn"
                                @click="nextStatusBtn(b.bill_id)">
                                {{ avaiableStatus[b.bill_status + 1] }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <OrderDetails v-if="showOrderDetails" :bill="sendId" >
            <button class="btn" @click="closeView">X</button>   
        </OrderDetails>
    </div>
</template>


<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import driverSign from './components/driverSign.vue'
import OrderDetails from "@/components/OrderDetails.vue";
export default {
    name: 'Home',
    components: {
        driverSign,
        OrderDetails,
    },


    data() {
        return {
            avaiableStatus: ["cancel", "confirmed", "preparing", "checking", "delivering", "delivered", "completed"],
            allBills: [],
            interval: "",
            showDriverSelect: false,
            sendId: null,
            showOrderDetails: false,
            restaurantUsed: null,
        }
    },


    created: function () {
        this.getAllBills();
        this.restaurantUsed = JSON.parse(localStorage.getItem('restaurant'))

    },
    mounted: function(){
        this.getDrivers();
        this.autoUpdate();

    },

    beforeUnmount() {
        clearInterval(this.interval)
    },

    computed: {
        ...mapState("users", ["admin", "user", "token", "restaurant"]),
        ...mapState('menu', ["allFoods"]),
        ...mapGetters('users', ["authObject"]),

        filterBills: function () {
            return this.allBills.filter((b) => b.bill_status < 6 && b.bill_status > 0);
        },
        // filterCompleted: function () {
        //     this.compBills.push( this.allBills.filter((b) => b.bill_status >= 6 && b.bill_status > 0))
        // },
    },

    methods: {
        ...mapMutations('users', ["SET_ADMIN"]),
        ...mapActions('users', ["logout"]),
        // for speecific rstaurant to be done, default Id 10
        async getAllBills() {
            try {


                if (this.token || this.admin) {
                    let restaurant_id = (JSON.parse(localStorage.getItem('restaurant'))).restaurant_id
                    this.allBills = (await axios.get('admin/billstatus/restaurant/'+ restaurant_id, this.authObject)).data;
                }
            } catch {
                (err) => {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        this.$router.push('/login');
                    } else {
                        console.error('An error occurred:', err.message);
                    }
                }
            }
        },


        handleLogout: function () {
            this.$router.push('/login')
            this.logout();

        },

        async nextStatusBtn(id) {
            await axios.get('admin/billstatus/' + id, this.authObject);
            this.getAllBills();
            this.showDriverSelect = true
        },

        async paidBtn(id) {
            await axios.get('admin/billstatus/paid/' + id, this.authObject);
            this.getAllBills();
        },

        async cancelBtn(id) {
            await axios.get('admin/billstatus/cancel/' + id, this.authObject);
            this.getAllBills();
        },

        autoUpdate: function () {

            this.interval = setInterval(function () {
                this.getAllBills();
            }.bind(this), 1000);

        },
        closeDriverSelect() {
            this.showDriverSelect = false;

        },
        sendBillId: function(id){
            this.sendId = id,
            this.showOrderDetails = !this.showOrderDetails
        },
        closeView: function () {
            this.showOrderDetails = !this.showOrderDetails;
        },
        async getDrivers() {
            console.log(this.restaurantUsed)
            await axios.get('/drivers/all/' + this.restaurantUsed.restaurant_id)
                .then(
                    (result) => {
                        localStorage.setItem("Drivers", JSON.stringify(result.data))
                    }
                )

        },
    },

}
</script>

<style scoped>
.admin-container {
    background-color: #fff;
    height: 100vh;
    padding: 2rem 9%;
    font-size: 16px;
    width: 100%;
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
    border-radius: 5px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
    border-radius: 5px
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>