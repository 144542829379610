<template>
    <div class="checkout-container">
        <div class="checkout-form-container">
            <form id="checkoutForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="checkout-heading">
                    <h3>Few more step to place your order<span>Total</span></h3>
                    <h3 v-if="user">{{ user.user_name }}'s Order<span>Tsh{{ calculateSummaryPrice()[3] }}</span></h3>
                </div>

                <div class="form-group details-group">
                    <h4>Delivery Details</h4>
                    <div class="form-group">
                        <input type="text" name="coPhone" id="coPhone" placeholder="Phone number (may used in payment)"
                            class="form-control" v-model="checkoutObj.phone" />
                        <p class="error-mess" v-if="errorObj.phoneErr.length > 0">{{ errorObj.phoneErr[0] }}</p>
                    </div>

                    <div class="form-group">
                        <input type="text" name="coAddress" id="coAddress"
                            placeholder="Address in House Number + Street Name format" class="form-control"
                            v-model="checkoutObj.address" />
                        <p class="error-mess" v-if="errorObj.addressErr.length > 0">{{ errorObj.addressErr[0] }}</p>
                    </div>
                </div>

                <div class="form-group details-group">
                    <h4>Payment Method</h4>
                    <div class="form-group">
                        <div class="form-group">
                            <input type="radio" name="payment" value="cash" id="paymentCash"
                                v-model="checkoutObj.paymentMethod" /><span>Cash</span>
                            <input type="radio" name="payment" value="azam" id="paymentCard"
                                v-model="checkoutObj.paymentMethod" /><span>Azam pay</span>
                        </div>
                        <p class="error-mess" v-if="errorObj.payErr.length > 0">{{ errorObj.payErr[0] }}</p>
                    </div>
                </div>
                <div class="form-group" v-if="checkoutObj.paymentMethod == 'azam'">
                    <input type="submit" value="CONFIRM AND PAY" class="btn"
                        :disabled="filterFoods.length ? false : true" />
                </div>
                <div class="form-group" v-if="checkoutObj.paymentMethod == 'cash'">
                    <input type="submit" value="CONFIRM" class="btn" :disabled="filterFoods.length ? false : true" />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from 'vuex';
export default {
    name: "Checkout",

    data() {
        return {
            checkoutObj: { phone: "", address: "", paymentMethod: "" },
            errorObj: { phoneErr: [], addressErr: [], payErr: [] },
            cartItem: [],
            itemQuantity: [],
            links: [
                'https://payments.azampay.co.tz/?id=380f2bb9-1ad2-4a34-8b66-5991e3dfe707&language=en',
                'https://payments.azampay.co.tz/?id=2bcdd699-141d-43c6-883f-6292790f4fae&language=en',
                'https://payments.azampay.co.tz/?id=611b54f4-7f3a-4830-91c7-92951956fa6e&language=en',
                'https://payments.azampay.co.tz/?id=9d8919e3-274c-4fea-9178-236adac9ef20&language=en',
                'https://payments.azampay.co.tz/?id=19d5dce4-7155-42ef-9b0d-be8ed8d866b5&language=en',
                'https://payments.azampay.co.tz/?id=de66c2dc-c78e-4e76-802a-814c415d6d89&language=en',
                'https://payments.azampay.co.tz/?id=95f5f886-9cf5-4382-879d-dbdb01344efb&language=en',
                'https://payments.azampay.co.tz/?id=659a8eff-36ca-476e-9e96-dbc2ad94d1cf&language=en',
                'https://payments.azampay.co.tz/?id=3df6081d-83ef-45fd-92a4-f6fb677ac40c&language=en'
            ]
        }
    },

    created() {
        this.getAllCartItem();
    },

    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token"]),
        ...mapState('menu', ["allFoods"]),

        filterFoods: function () {
            return this.allFoods.filter(
                (f) => this.matchID(f, this.cartItem)
            );
        },
        currentLink() {
            if (this.calculateSummaryPrice()[3] == 4000) {
                return this.links[0];
            } else if (this.calculateSummaryPrice()[3] == 6000) {
                return this.links[1];
            } else if (this.calculateSummaryPrice()[3] == 8000) {
                return this.links[2];
            } else if (this.calculateSummaryPrice()[3] == 10000) {
                return this.links[3];
            } else if (this.calculateSummaryPrice()[3] == 11000) {
                return this.links[4];
            }
            else if (this.calculateSummaryPrice()[3] == 1000) {
                return this.links[5];
            } else if (this.calculateSummaryPrice()[3] == 2000) {
                return this.links[5];
            }
            else if (this.calculateSummaryPrice()[3] == 5000) {
                return this.links[6];
            }
            else if (this.calculateSummaryPrice()[3] == 9000) {
                return this.links[8];
            } else {
                return ''; // Default link or handle other cases
            }
        }
    },

    methods: {

        matchID: function (food, cartArray) {
            let temp = "";
            cartArray.forEach(element => {
                if (parseInt(food.food_id) == element) {
                    temp = food
                }
            });
            return temp
        },

        calculateSummaryPrice: function () {
            let subtotal = 0;
            let discount = 0;
            let delivery = 1000;
            let i = 0;
            while (i < this.itemQuantity.length) {
                subtotal = subtotal + parseInt(this.filterFoods[i].food_price) * this.itemQuantity[i]
                discount = discount + parseInt(this.filterFoods[i].food_discount) * this.itemQuantity[i]
                i = i + 1
            }
            if (!this.filterFoods.length) {
                delivery = 0
            }
            let total = subtotal - discount + delivery;
            return [subtotal, discount, delivery, total];
        },

        async getAllCartItem() {
            if (this.user) {
                await axios.get('/cartItem/' + this.user.user_id, this.authObject)
                    .then(
                        (existItem) => {
                            existItem.data.forEach(element => {
                                this.cartItem.push(element.food_id);
                                this.itemQuantity.push(element.item_qty);
                            });
                        }
                    )
                    .catch(
                        (err) => {
                            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                                localStorage.removeItem('access_token')
                                localStorage.removeItem('user')
                                // Redirect to login page using Vue Router
                                this.$router.push('/login');
                            } else {
                                // Handle other types of errors
                                console.error('An error occurred:', err.message);
                                this.$refs.alert.showAlert('failure', 'Error ocured', `${err.message}`)
                            }
                        }
                    )

            }
        },

        resetCheckErr: function () {
            this.errorObj.payErr = [];
            this.errorObj.phoneErr = [];
            this.errorObj.addressErr = [];
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        inputUpcase: function (e) {
            e.target.value = e.target.value.toUpperCase()
        },

        checkForm: function () {
            this.resetCheckErr();

            // Phone validate
            if (!this.checkoutObj.phone) {
                this.errorObj.phoneErr.push('Entering phone number is required');
            }
            else {
                if (!this.checkoutObj.phone.startsWith('0')) {
                    this.errorObj.phoneErr.push('Phone numbers must start with 0');
                }

                if (this.checkoutObj.phone.length != 10) {
                    this.errorObj.phoneErr.push('Phone numbers must have exactly 10 digits');
                }

                if (!/[0-9]{10}/.test(this.checkoutObj.phone)) {
                    this.errorObj.phoneErr.push('Phone numbers can only contain numbers');
                }
            }

            // Address validate
            if (!this.checkoutObj.address) {
                this.errorObj.addressErr.push('Entering address is required');
            }

            // Card validate
            if (!this.checkoutObj.paymentMethod) {
                this.errorObj.payErr.push('Selecting payment method is required');
            }
            else if (this.checkoutObj.paymentMethod == "cash") {

                this.errorObj.addressErr = [];
            }
        },

        isPaid: function () {
            if (this.checkoutObj.paymentMethod == "cash") {
                return "false"
            }
            else if (this.checkoutObj.paymentMethod == "azam") {
                return "Confirm At Azam Dashboard"
            }
        },

        async sendBillDetails(billId, foodId, qty) {
            let billDetails = {
                bill_id: parseInt(billId),
                food_id: parseInt(foodId),
                item_qty: parseInt(qty)
            }

            await axios.post("/billdetails", billDetails, this.authObject)
                .then(
                    (response) => {
                        console.log(response)
                        //add toast

                    }
                )
                .catch(
                    (err) => {
                        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('user')
                            // Redirect to login page using Vue Router
                            this.$router.push('/login');
                        } else {
                            // Handle other types of errors
                            console.error('An error occurred:', err.message);
                            this.$refs.alert.showAlert('failure', 'Error ocured', `${err.message}`)
                        }
                    }
                )
        },

        async handleSubmit(e) {
            e.preventDefault(); // Prevent default form submission behavior
            this.checkForm();

            if (!this.checkEmptyErr()) {
                return;
            }

            try {
                let billId = (await axios.get("/billstatus/new", this.authObject)).data;

                if (billId == "") {
                    billId = 1;
                } else {
                    billId = parseInt(billId.bill_id) + 1;
                }

                this.cartItem.forEach((foodId, index) => {
                    this.sendBillDetails(billId, foodId, this.itemQuantity[index]);
                });

                var now = new Date();
                var day = ("0" + now.getDate()).slice(-2);
                var month = ("0" + (now.getMonth() + 1)).slice(-2);
                var hour = ("0" + (now.getHours())).slice(-2);
                var min = ("0" + (now.getMinutes())).slice(-2);
                var currentTime = now.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + min;

                let billStatus = {
                    bill_id: parseInt(billId),
                    user_id: parseInt(this.user.user_id),
                    bill_phone: this.checkoutObj.phone,
                    bill_address: this.checkoutObj.address,
                    bill_when: currentTime,
                    bill_method: this.checkoutObj.paymentMethod,
                    bill_discount: parseInt(this.calculateSummaryPrice()[1]),
                    bill_delivery: parseInt(this.calculateSummaryPrice()[2]),
                    bill_total: parseInt(this.calculateSummaryPrice()[3]),
                    bill_paid: this.isPaid(),
                    bill_status: 1
                };

                await axios.post("/billstatus", billStatus, this.authObject);
                await axios.delete("/cartItem/" + this.user.user_id, this.authObject);

                window.open(this.currentLink, '_blank', 'width=800,height=600');

                this.cartItem = [];
                this.itemQuantity = [];

                
                    this.$router.push("/thank");
                

            } catch (err) {
                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    this.$router.push('/login');
                } else {
                    console.error('An error occurred:', err.message);
                }
            }
        }

    }
};
</script>

<!-- <script setup>
// enables v-focus in templates
const vUpcase = {
    mounted(el) {
        el.style.textTransform = "uppercase";
    }
}
</script> -->

<style scoped>
.checkout-container {
    padding: 2rem 9%;
}

.checkout-container .checkout-form-container {
    background: #fff;
    height: 90vh;
}

.checkout-container .checkout-form-container form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -42%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.checkout-container .checkout-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.checkout-container .checkout-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    padding: 2rem 1.2rem;
    font-size: 1.6rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
}

.checkout-container .checkout-form-container form label {
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.checkout-container .checkout-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
}

.checkout-container .checkout-form-container form .btn {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
}

.checkout-container .checkout-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.checkout-container .checkout-form-container form p a {
    color: #27ae60;
}

.checkout-container .checkout-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.checkout-container .checkout-form-container form .form-group {
    margin: 0;
}

.checkout-container .checkout-form-container form .form-group.details-group {
    margin-top: 40px;
}

.checkout-container .checkout-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}

.checkout-container .checkout-form-container form .checkout-heading h3 {
    display: flex;
    justify-content: space-between;
}

.checkout-container .checkout-form-container form .checkout-heading h3 span {
    padding-right: 0px;
    color: #f38609;
}

.checkout-container .checkout-form-container form .checkout-heading h3:first-of-type span {
    padding-right: 0px;
    color: #130f40;
}
</style>
