<template>
    <div class="admin-container">
      <h1>Settings</h1>
      <p>Adjust your settings here.</p>
    </div>
  </template>
  <style>
.admin-container {
    background-color: #fff;
    height: 100vh;
    padding: 2rem 9%;
    font-size: 16px;
    width: 100%;
}
</style>