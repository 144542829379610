<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />

    <div class="quick-view">
        <div class="quick-view-inner">
            <h2 class="d-flex justify-content-between">Rate our service
                <slot></slot>
            </h2>
            <div class="product-detail d-inline">
                <div class="content">

                    <star-rating :active-color="starColor" increment=0.5 @hover:rating="updateColor"
                        @update:rating="setRating" />


                </div>
                <div class="content">
                    <textarea name="coment" id="" cols="18" rows="2" v-model="coments" placeholder="Adition comments"></textarea>
                    <button class="btn btn-outline-secondary btn-small" @click="submitRating" >Submit</button>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from 'vuex';
import VueBasicAlert from 'vue-basic-alert';
import StarRating from 'vue-star-rating';
export default {
    props: ['bill_id'],
    name: "QuickView",

    data() {
        return {
            showRating: false,
            rating: null,
            starColor: '#FF0000', // Default color set to red
            coments: null
        }
    },

    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token"]),
        ...mapState('menu', ["allFoods"]),

    },

    methods: {
        async addToCart() {

            await axios.get('/cartItem/' + parseInt(this.user.user_id) + '/' + parseInt(this.food), this.authObject)
                .then(
                    (existItem) => {
                        if (existItem.data.length == 1) {
                            let data = {
                                user_id: parseInt(this.user.user_id),
                                food_id: parseInt(this.food),
                                item_qty: parseInt(this.qty) + parseInt(existItem.data[0].item_qty)
                            };
                            axios.put("/cartItem/", data)
                                .then(
                                    (result) => {
                                        this.$refs.alert.showAlert('success', 'Thank you!', 'Add To Cart Successfully !')
                                        console.log(result)
                                    }
                                )


                        } else {
                            let data = {
                                user_id: parseInt(this.user.user_id),
                                food_id: parseInt(this.food),
                                item_qty: parseInt(this.qty)
                            };

                            try {
                                axios.post('/cartItem', data)
                                    .then(
                                        (response) => {
                                            console.log('Response:', response.data);
                                            this.$refs.alert.showAlert('success', 'Thank you!', 'Add To Cart Successfully !')

                                        }
                                    )
                            } catch (error) {
                                console.error('Error:', error.response ? error.response.data : error.message);
                                this.$refs.alert.showAlert('failure', `Sorry, failed to add to cart due to this error ${error.message}`)
                            }

                        }
                    }
                )
                .catch(
                    (err) => {
                        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('user')
                            this.$refs.alert.showAlert('failure', 'Error ocured', `${err.message}`)
                            // Redirect to login page using Vue Router
                            setTimeout(this.$router.push('/login'), 1000)

                        } else {
                            // Handle other types of errors
                            console.error('An error occurred:', err.message);
                        }
                    }
                )


        },
        setRating(rating) {
            this.rating = rating;
            console.log(rating);
        },
        updateColor(rating) {
            if (rating <= 2.5) {
                this.starColor = '#FF0000'; // Hex code for red
            } else {
                this.starColor = '#008000'; // Hex code for green
            }
        },
        submitRating() {
            let data = {
                rating: this.rating,
                bill_id: this.bill_id,
                comment: this.coments,
                user_id: this.user.user_id
            }
            try{
                axios.post('/rating/', data);
                this.$refs.alert.showAlert('success', 'Thank you!', 'Reted Successfully !')
            }
            catch{
                (err) => {
                    console.log(err)
                }
            }
            this.showRating = !this.showRating
        }

    },

    components: {
        VueBasicAlert,
        StarRating
    }
}
</script>

<style scoped>
.quick-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;
}

.quick-view .quick-view-inner {
    width: 45vw;
    height: 45vh;
    background-color: #fff;
    padding: 32px;
}


.quick-view .quick-view-inner h2 {
    margin: 0;
    font-size: 32px;
    color: #27ae60;
}

.quick-view .quick-view-inner .product-detail .image img {
    height: 20rem;
    margin: 20px;
}

.quick-view .quick-view-inner .product-detail .content {
    margin-top: 20px;
    font-size: 20px;
    width: 100%;
}

.quick-view .quick-view-inner .product-detail .content p span {
    margin-left: 5px;
    text-decoration: line-through;
    opacity: 0.5;
}

.quick-view .quick-view-inner .product-detail .content div label {
    margin-right: 10px;
}

.quick-view .quick-view-inner .product-detail .content .btn {
    margin-top: 20px;
    float: right;
}

@media (max-width: 768px) {

    .quick-view .quick-view-inner {
        width: 50vw;
        height: 40vh;

    }

    .quick-view .quick-view-inner h2 {
        font-size: 20px;
    }

    .quick-view .quick-view-inner .btn {
        font-size: 10px;
        padding: 0.3rem 0.9rem;
    }

    .quick-view .quick-view-inner .product-detail .image img {
        height: 12rem;
        margin: 30px;
        margin-left: 0px;

    }

    .quick-view .quick-view-inner .product-detail .content .desc {
        font-size: 12px;
    }

    .quick-view .quick-view-inner .product-detail .content .qty {
        font-size: 12px;
    }

    .link-to-login {
        margin-top: 20px !important;
    }
}

@media (max-width: 576px) {
    .quick-view .quick-view-inner {
        width: 90vw;
        height: 40vh;
    }

    .link-to-login {
        margin-top: 50px !important;
    }

    .link-to-login>a {
        padding: 20px !important;
        font-size: 18px !important;
    }

}
</style>
