import Axios from 'axios'
//const baseUrl = 'https://node-shop-ws.herokuapp.com';

const state = {

    token: localStorage.getItem('access_token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
    admin: undefined,
    restaurant: localStorage.getItem('restaurant') || null,
    driver: JSON.parse(localStorage.getItem('driver') ) || null

}
const getters = {
    loggedIn(state) {
        return state.token !== null;
    },
    authObject(state) {
        return {
            headers: {
                Authorization: `Bearer ${state.token}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        };
    }
}
const mutations = {
    SET_TOKEN(state, token) {
        state.token = token
    },
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_ADMIN(state, payload) {
        state.admin = payload;
    },
    SET_RESTAURANT(state, payload) {
        state.restaurant = payload
    },
    SET_DRIVER(state, payload) {
        state.driver = payload
    },
    LOG_OUT(state) {
        state.user = null,
            state.token = null,
            state.admin = undefined,
            state.restaurant = null
            state.driver = null

    }
}
const actions = {
    retrieveToken({ commit }, { email, password }) {

        return new Promise((resolve, reject) => {
            Axios.post('/user/login', {
                user_email: email,
                user_password: password
            })
                .then(response => {
                    const token = response.data.token
                    localStorage.setItem('access_token', token)
                    commit('SET_TOKEN', token)
                    console.log(response)
                    const user = JSON.stringify(response.data.user)
                    localStorage.setItem('user', user)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })
    },
    logout({ commit }) {
        commit('LOG_OUT')
        localStorage.removeItem('access_token')
        localStorage.removeItem('user')
        localStorage.removeItem('restaurant')
    }
}

export default {
    state, getters, mutations, actions, namespaced: true,
}