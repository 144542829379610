import axios from "axios";
window.axios = axios
axios.defaults.withCredentials = true
// axios.defaults.baseURL = "http://localhost:8081/api" foe localhost
// uncoment bellow on localhost
// let backendUrl = "http://" + window.location.hostname.toString() + ":8081/api"

//for live application
let backendUrl = "https://nitume-backend-cab3fd2ac123.herokuapp.com/api"
axios.defaults.baseURL = backendUrl
