<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section class="order-section">

        <div class="heading">
            <span>Update profile</span>
            <h3>enjoy your moment</h3>
        </div>

        <div class="icons-container">
            <!-- Deal with it later -->
            <div class="icons">
                <img src="../assets/images/user.png" alt="">
                <h3>{{ userObj.name }}</h3>
            </div>

            <div class="icons">
                <img src="../assets/images/icon-2.png" alt="">
                <h3>{{ userObj.email }}</h3>
            </div>

            <div class="icons">
                <img src="../assets/images/icon-3.png" alt="">
                <h3 v-if="userObj.address"> {{ userObj.address }}</h3>
                <h3 v-else>06 Majumbasita Dar es salaam</h3>
            </div>

        </div>

        <!-- booking form -->
        <form id="profileForm" @submit.prevent="handleSubmit" novalidate autocomplete="off">

            <div class="row">
                <div class="input-box">
                    <label for="uName">your name</label>
                    <input type="text" name="uName" id="uName" v-model="userObj.name">

                </div>
                <div class="input-box">
                    <label for="uPhone">your phone number</label>
                    <input type="text" name="uPhone" id="uPhone" v-model="userObj.phone">

                </div>
            </div>

            <div class="row">

                <div class="input-box">
                    <label for="opassword">Password</label>
                    <input type="password" name="opassword" id="opassword" v-model="userObj.password">

                </div>
                <div class="input-box">
                    <label for="uName">Address</label>
                    <input type="text" name="address" id="address" v-model="userObj.address">

                </div>

            </div>

            <div class="row">
                <div class="input-box">
                    <label for="uconfirmPass">Confirm password</label>
                    <input type="password" name="uconfirmPass" id="uconfirmPass" v-model="userObj.confirmPass">
                    <p v-if="userObj.passErr.length > 0">{{ userObj.passErr[0] }}</p>

                </div>
                <div class="input-box">
                    <label for="obirthday">Birthday</label>
                    <input type="date" placeholder="Birth date" name="obirthday" id="uBirth" v-model="userObj.birthday"
                        @click="availableTime()">

                </div>
            </div>

            <input type="submit" value="Update Profile" class="btn">
        </form>

    </section>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
import { mapGetters, mapState } from 'vuex';
export default {
    name: "userProfile",

    created() {
        this.getUserData()
    },
    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token"]),
    },

    data() {
        return {
            userObj: { name: "", phone: "", password: "", confirmPass: "", birthday: "", email: "", address: "", passErr: [] }
        }
    },

    methods: {
        availableTime: function () {
            var now = new Date();
            var day = ("0" + now.getDate()).slice(-2);
            var currentMonth = ("0" + (now.getMonth() + 1)).slice(-2);
            var minRange = (now.getFullYear() - 150) + "-" + currentMonth + "-" + day;
            var maxRange = now.getFullYear() + "-" + currentMonth + "-" + day;

            document.getElementById("uBirth").setAttribute("min", minRange);
            document.getElementById("uBirth").setAttribute("max", maxRange);
        },



        async getUserData() {
            if (this.user) {
                await axios.get("/users/update/" + this.user.email, this.authObject)
                    .then(
                        (result) => {
                            console.log(result.data)
                            this.userObj.name = result.data.user_name
                            this.userObj.phone = result.data.user_phone
                            this.userObj.birthday = result.data.user_birth
                            this.userObj.email = result.data.user_email
                            this.userObj.address = result.data.user_address

                        }
                    )
                    .catch(
                        (err) => {
                            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                                localStorage.removeItem('access_token')
                                localStorage.removeItem('user')
                                // Redirect to login page using Vue Router
                                this.$router.push('/login');
                            } else {
                                // Handle other types of errors
                                console.error('An error occurred:', err.message);
                            }

                        }
                    )
            }
        },





        async handleSubmit(e) {
            e.preventDefault();  // Prevent the default form submission



            // If validation passes, proceed with the POST request
            let data = {
                user_name: this.userObj.name,
                user_phone: parseInt(this.userObj.phone),
                user_password: this.userObj.password,
                user_birth: this.userObj.birthday,
                user_email: this.userObj.email,
                user_address: this.userObj.address
            };
            console.log(data);
            if (this.userObj.password) {
                if (this.userObj.password !== this.userObj.confirmPass) {
                    this.userObj.passErr.push("The password must match to complete.")
                    console.log("pass err")
                }
                else {
                    try {
                        let result = await axios.post("/user/update", data, this.authObject);
                        console.log(result);

                        this.$refs.alert.showAlert('success', 'Thank you, your information updated successfully');
                        this.$router.push('/home');
                    } catch (err) {
                        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                            this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                            localStorage.removeItem('access_token');
                            localStorage.removeItem('user');
                            this.$router.push('/login');
                        } else {
                            console.error('An error occurred:', err.message);
                        }
                    }
                }
            }
            else {

                try {
                    let result = await axios.post("/user/update", data, this.authObject);
                    console.log(result);

                    this.$refs.alert.showAlert('success', 'Thank you, your information updated successfully');
                    this.$router.push('/');
                } catch (err) {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        this.$router.push('/login');
                    } else {
                        console.error('An error occurred:', err.message);
                    }
                }
            }
        }
    },

    components: {
        VueBasicAlert
    }

}
</script>

<style scoped>
.order-section {
    padding: 2rem 9%;
    width:100%;
}

.order-section .icons-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.order-section .icons-container .icons {
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
}

.order-section .icons-container .icons img {
    height: 10rem;
}

.order-section .icons-container .icons h3 {
    font-size: 2rem;
    color: #130f40;
    margin-top: .5rem;
}

.order-section form {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: .5rem;
}

.order-section form .row {
    justify-content: space-between;
}

.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
}

.order-section form .row label {
    font-size: 1.7rem;
    color: #666;
}

.order-section form .row p {
    font-size: 1.5rem;
    position: absolute;
    color: rgb(243, 47, 47);
    margin: 0;
    padding-top: 5px;
}

.order-section form .row input,
.order-section form .row textarea {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.order-section form .row textarea {
    height: 20rem;
    resize: none;
}

form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
}

.order-section form .row .map {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .order form .row .input-box {
        width: 100%;
    }

    .order-section form .row {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 0;
    }

    .order-section form .row .input-box {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .order-section .icons-container {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}
</style>