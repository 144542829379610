<template>


    <div class="admin-container">


        <div class="row">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Total Delivery </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">38</div>
                                <!-- must be qeried from the db -->
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold  text-success text-uppercase mb-1">
                                    Income (Monthly)</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ allBills.length }}</div>
                                <!-- number has to be fetched -->
                            </div>
                            <div class="col-auto">
                                <div class="fas fa-shopping-cart cart fa-2x"></div>
                                <!-- <i class="fas fa-shopping-cart cart"></i> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tasks Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Completed Delivery
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">100%</div>
                                    </div>
                                    <div class="col">
                                        <!-- means to deal with progress -->
                                        <div class="progress progress-sm mr-2">
                                            <div class="progress-bar bg-info" role="progressbar" style="width: 100%"
                                                aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fa-solid fa-square-check fa-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pending Requests Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    New</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">03</div>
                                <!-- from db -->
                            </div>
                            <div class="col-auto">
                                <!-- <i class="fas fa-comments fa-2x text-gray-300"></i> -->
                                <i class="fa-solid fa-spinner fa-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <!-- PROJECT TABLE -->
            <table class="table colored-header datatable project-list">
                <thead>
                    <tr>
                        <th>Bill Id</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>When</th>
                        <th>Paid</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(b) in filterBills.slice().reverse()" :key="b.bill_id">
                        <td>{{ b.bill_id }}</td>
                        <td>{{ knowUser(b.user_id) }}</td>
                        <td>{{ b.bill_phone }}</td>
                        <td>{{ b.bill_address }}</td>
                        <td>{{ b.bill_when }}</td>
                        <td>{{ b.bill_paid }}</td>
                        <td>Tsh {{ b.bill_total }}</td>
                        <td>{{ avaiableStatus[b.bill_status] }}</td>
                        <td>
                            <button v-if="b.bill_status < 5 && b.bill_status >= 3" class="action-btn"
                                @click="nextStatusBtn(b.bill_id)">
                                {{ avaiableStatus[b.bill_status + 1] }}
                            </button>
                            

                            <button v-if="b.bill_status == 5 && b.bill_paid != 'true'" class="paid-btn"
                                @click="paidBtn(b.bill_id)">
                                Paid
                            </button>

                            <button v-else-if="b.bill_status == 5 && b.bill_paid == 'true'" class="action-btn"
                                @click="nextStatusBtn(b.bill_id)">
                                {{ avaiableStatus[b.bill_status + 1] }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
    name: 'Home',


    data() {
        return {
            avaiableStatus: ["cancel", "confirmed", "preparing", "checking", "delivering", "delivered", "completed"],
            allBills: [],
            myOrders: [],
            showOrderDetails: false,
            sendId: undefined,
            interval: "",
            users: [],
        }
    },

    created: function () {
        this.fetchUsers();
    },
    mounted: function () {
        this.autoUpdate();
        this.getAllBills()
    },

    beforeUnmount() {
        clearInterval(this.interval)
    },

    computed: {
        ...mapState("users", ["admin", "user", "token"]),
        ...mapState('menu', ["allFoods"]),
        ...mapGetters('users', ["authObject"]),

        filterBills: function () {
            return this.allBills.filter((b) => b.bill_status < 6 && b.bill_status > 0);
        },
        // filterCompleted: function () {
        //     this.compBills.push( this.allBills.filter((b) => b.bill_status >= 6 && b.bill_status > 0))
        // },
    },

    methods: {
        ...mapMutations('users', ["SET_ADMIN"]),
        ...mapActions('users', ["logout"]),
        // for speecific rstaurant to be done, default Id 10
        async getAllBills() {
            try {
                let curentDriver = await JSON.parse(localStorage.getItem('driver'))
                if (this.token || this.admin) {
                    this.allBills = (await axios.get('driver/billstatus/' + curentDriver[0].driver_id, this.authObject)).data;
                }
            } catch {
                (err) => {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        this.$router.push('/login');
                    } else {
                        console.error('An error occurred:', err.message);
                    }
                }
            }
        },

        sendBillId: function (id) {
            this.sendId = id
            this.showOrderDetails = !this.showOrderDetails;
        },

        closeView: function () {
            this.showOrderDetails = !this.showOrderDetails;
        },

        handleLogout: function () {
            this.$router.push('/login')
            this.logout();

        },

        async nextStatusBtn(id) {
            await axios.get('admin/billstatus/' + id, this.authObject);
            this.getAllBills();
        },

        async paidBtn(id) {
            await axios.get('admin/billstatus/paid/' + id, this.authObject);
            this.getAllBills();
        },

        async cancelBtn(id) {
            await axios.get('admin/billstatus/cancel/' + id, this.authObject);
            this.getAllBills();
        },
        async fetchUsers() {
            await axios.get('/users/').then(
                (result) => {
                    console.log(result.data)
                    this.users = result.data
                    return result.data.user_name
                }
            )

        },
        knowUser(id) {
            const userFound = this.users.find(u => u.user_id === id);
            if (userFound) {
                return userFound.user_name;
            } else {
                return 'User not found';
            }
        },

        autoUpdate: function () {



            this.interval = setInterval(function () {
                this.getAllBills();
            }.bind(this), 1000);

        }

    },
}
</script>

<style scoped>
.admin-container {
    background-color: #fff;
    height: 100vh;
    padding: 2rem 9%;
    font-size: 16px;
    width: 100%;
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>