<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />

    <div class="quick-view">
        <div class="quick-view-inner">
            <h2 class="d-flex justify-content-between">Choose driver to deliver
                <slot></slot>
            </h2>
            <div class="product-detail d-inline">
                
                <div class="content">
                   <select name="driver" id="" v-model="selectedDriver" class="input-box" >
                    <option v-for="driver in drivers" :key="driver.driver_id" :value="driver.driver_id">{{ knowUser(driver.user_id) }}</option>
                   </select>
                    <button class="btn btn-outline-secondary btn-small" @click="submitDriver" >Submit</button>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from 'vuex';
import VueBasicAlert from 'vue-basic-alert';
export default {
    props: ['bill_id'],
    name: "dvierSigning",

    created() {
        this.restaurantUsed = JSON.parse(localStorage.getItem('restaurant'))
    },

    mounted() {
        this.getDrivers()
        this.fetchUsers();
    },

    data() {
        return {
            showRating: false,
            rating: null,
            drivers: [],
            restaurantUsed: null,
            selectedDriver: null,
            usersAll: []
            
        }
    },

    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token", "restaurant"]),
        ...mapState('menu', ["allFoods"]),

    },

    methods: {
      async  getDrivers(){
            console.log(this.restaurantUsed)
           await axios.get('/drivers/all/'+ this.restaurantUsed.restaurant_id)
           .then(
            (result) => {
                this.drivers = result.data;
                console.log(result.data)
                console.log(this.drivers)
            }
           )

        }
        ,
        async fetchUsers() {
            await axios.get('/users/').then(
                (result) => {
                    console.log(result.data)
                    this.usersAll = result.data
                    return result.data.user_name
                }
            )

        },
        knowUser(id) {
            const userFound = this.usersAll.find(u => u.user_id === id);
            if (userFound) {
                return userFound.user_name;
            } else {
                return 'User not found';
            }
        },


        submitDriver() {
            let data = {
                bill_id: this.bill_id,
                driver_id: this.selectedDriver
            }
            try{
                axios.post('/driver/assign/', data);
                this.$refs.alert.showAlert('success', 'Congratulations!', 'Driver assigned Successfully !')
            }
            catch{
                (err) => {
                    console.log(err)
                }
            }
        }

    },

    components: {
        VueBasicAlert,
    }
}
</script>

<style scoped>
.quick-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;
}
.input-box{
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.quick-view .quick-view-inner {
    width: 45vw;
    height: 40vh;
    background-color: #fff;
    padding: 32px;
}


.quick-view .quick-view-inner h2 {
    margin: 0;
    font-size: 32px;
    color: #27ae60;
}

.quick-view .quick-view-inner .product-detail .image img {
    height: 20rem;
    margin: 20px;
}

.quick-view .quick-view-inner .product-detail .content {
    margin-top: 20px;
    font-size: 20px;
    width: 100%;
}

.quick-view .quick-view-inner .product-detail .content p span {
    margin-left: 5px;
    text-decoration: line-through;
    opacity: 0.5;
}

.quick-view .quick-view-inner .product-detail .content div label {
    margin-right: 10px;
}

.quick-view .quick-view-inner .product-detail .content .btn {
    margin-top: 20px;
    float: right;
}

@media (max-width: 768px) {

    .quick-view .quick-view-inner {
        width: 50vw;
        height: 40vh;

    }

    .quick-view .quick-view-inner h2 {
        font-size: 20px;
    }

    .quick-view .quick-view-inner .btn {
        font-size: 10px;
        padding: 0.3rem 0.9rem;
    }

    .quick-view .quick-view-inner .product-detail .image img {
        height: 12rem;
        margin: 30px;
        margin-left: 0px;

    }

    .quick-view .quick-view-inner .product-detail .content .desc {
        font-size: 12px;
    }

    .quick-view .quick-view-inner .product-detail .content .qty {
        font-size: 12px;
    }

    .link-to-login {
        margin-top: 20px !important;
    }
}

@media (max-width: 576px) {
    .quick-view .quick-view-inner {
        width: 90vw;
        height: 40vh;
    }

    .link-to-login {
        margin-top: 50px !important;
    }

    .link-to-login>a {
        padding: 20px !important;
        font-size: 18px !important;
    }

}
</style>
