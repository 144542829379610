
import Vuex from 'vuex'

import users from './modules/users'
import menu from './modules/menu'

export const store =  new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    menu
  }
})