<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section class="order-section">
        <table class="table table-hover tbl-dr">
            <thead>
                <tr class="table-active">
                    <th scope="col">#</th>
                    <th scope="col">Driver name</th>
                    <th scope="col">Vehicle number</th>
                    <th scope="col">Lincence Number</th>
                </tr>
            </thead>
            <tbody v-if="drivers.length > 0">
                <tr v-for="(driver, index) in drivers" :key="index" >
                    <td scope="col">{{ index + 1 }}</td>
                    <td>{{ knowUser(driver.user_id) }}</td>
                    <td>{{ driver.vehicle_number }}</td>
                    <td>{{ driver.lincence_number }}</td>
                </tr>
            </tbody>
            <tbody v-else >
                <tr>
                    <td colspan="4" class="text-center" >You dont have any driver yet! please register one</td>
                </tr>
            </tbody>

        </table>
        <div class="d-grid py-2 gap-2 d-md-flex justify-content-md-end">
            <button class="btn btn-small btn-outline-success" v-if="!showRegistration" @click="showRegister">Register new driver</button>
            <button class="btn btn-small btn-outline-success" v-else @click="showRegister" >Close Form</button>
        </div>
        <div v-if="showRegistration">
            <div class="heading">
                <span>Driver Registration</span>
                <h3>Streamlined delivery</h3>
            </div>

            <div class="icons-container">
                <!-- Deal with it later -->
                <div class="icons">
                    <img src="../../assets/images/user.png" alt="">
                    <h3>{{ driverObj.name }}</h3>
                </div>

                <div class="icons">
                    <img src="../../assets/images/email11.png" alt="">
                    <h3>{{ driverObj.email }}</h3>
                </div>

                <div class="icons">
                    <img src="../../assets/images/icon-3.png" alt="">
                    <h3 v-if="driverObj.address"> {{ driverObj.address }}</h3>
                    <h3 v-else>Pending ..</h3>
                </div>

            </div>

            <!-- booking form -->
            <form id="profileForm" @submit.prevent="handleSubmit" novalidate autocomplete="off">

                <div class="row">
                    <div class="input-box">
                        <label for="uName">Driver name</label>
                        <input type="text" name="uName" id="uName" v-model="driverObj.name">

                    </div>
                    <div class="input-box">
                        <label for="uPhone">Driver Email</label>
                        <input type="text" name="uPhone" id="uPhone" v-model="driverObj.email">

                    </div>
                </div>

                <div class="row">

                    <div class="input-box">
                        <label for="opassword">Password</label>
                        <input type="password" name="opassword" id="opassword" v-model="driverObj.password">

                    </div>
                    <div class="input-box">
                        <label for="uName">Driver Phone</label>
                        <input type="number" name="address" id="address" v-model="driverObj.phone">

                    </div>

                </div>

                <div class="row">
                    <div class="input-box">
                        <label for="uconfirmPass">Confirm password</label>
                        <input type="password" name="uconfirmPass" id="uconfirmPass" v-model="driverObj.confirmPass">
                        <p v-if="driverObj.passErr.length > 0">{{ driverObj.passErr[0] }}</p>

                    </div>
                    <div class="input-box">
                        <label for="obirthday">Address</label>
                        <input type="text" name="obirthday" id="uBirth" v-model="driverObj.address" />

                    </div>
                </div>

                <input type="submit" value="Register driver" class="btn">

            </form>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
import { mapGetters, mapState } from 'vuex';
export default {
    name: "Driver",

    created() {
        // this.getUserData()
        this.restaurantUsed = JSON.parse(localStorage.getItem('restaurant'))
        this.drivers = JSON.parse(localStorage.getItem("Drivers"));
    },
    mounted() {
        this.fetchUsers();

    },
    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token", "restaurant"]),
    },

    data() {
        return {
            driverObj: { name: "", phone: "", password: "", confirmPass: "", email: "", address: "", passErr: [], emailErr: [] },
            usersAll: [],
            drivers: [],
            restaurantUsed: null,
            showRegistration: false
        }
    },

    methods: {
       
        async fetchUsers() {
            await axios.get('/users/').then(
                (result) => {
                    console.log(result.data)
                    this.usersAll = result.data
                    return result.data.user_name
                }
            )

        },
        knowUser(id) {
            const userFound = this.usersAll.find(u => u.user_id === id);
            if (userFound) {
                return userFound.user_name;
            } else {
                return 'User not found';
            }
        },
        async getMatchUser(email) {
            let data = await axios.get('/users/' + email);
            this.matchUser = data.data;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
        showRegister(){
            this.showRegistration = !this.showRegistration
        },

        async handleSubmit(e) {


            e.preventDefault();
            if (this.driverObj.password && this.driverObj.password !== this.driverObj.confirmPass) {
                this.driverObj.passErr.push("The password must match to complete.")
                console.log("pass err")
            }
            else {

                await this.getMatchUser(this.driverObj.email);
                if (this.matchUser) {
                    this.driverObj.emailErr.push("Account already exist")
                    this.$refs.alert.showAlert('info', 'The email taken');
                }
                else {
                   
                    let data = {
                        user_name: this.driverObj.name,
                        user_email: this.driverObj.email,
                        user_phone: this.driverObj.phone,
                        user_password: this.driverObj.password,
                        user_birth: 'Not Applicable',
                        user_gender: 'Not Applicable',
                        user_address: this.driverObj.address,
                        role: 2
                    }
                    await axios.post("/users/register/", data)
                        .then(
                            (result) => {
                                console.log(result)
                                

                                let data = {
                                    user_id: result.data.insertId,
                                    restaurant_id: this.restaurantUsed.restaurant_id
                                }
                                console.log(result.data)
                                axios.post('/drivers/register', data, this.authObject);
                                this.showRegister()
                                this.$refs.alert.showAlert('success', 'Your driver registered');
                                
                            }
                        )
                        .catch(
                            (err) => {
                                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                                    this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                                    localStorage.removeItem('access_token');
                                    localStorage.removeItem('user');
                                    this.$router.push('/login');
                                } else {
                                    console.error('An error occurred:', err.message);
                                }
                            }
                        )

                }
            }


        }
    },

    components: {
        VueBasicAlert
    }

}
</script>

<style scoped>
.order-section {
    padding: 2rem 9%;
    width: 100%;
}
.tbl-dr, .tbl-dr tbody tr{
    font-size: 12px;
}

.order-section .icons-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.order-section .icons-container .icons {
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
}

.order-section .icons-container .icons img {
    height: 10rem;
}

.order-section .icons-container .icons h3 {
    font-size: 2rem;
    color: #130f40;
    margin-top: .5rem;
}

.order-section form {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: .5rem;
}

.order-section form .row {
    justify-content: space-between;
}

.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
}

.order-section form .row label {
    font-size: 1.7rem;
    color: #666;
}

.order-section form .row p {
    font-size: 1.5rem;
    position: absolute;
    color: rgb(243, 47, 47);
    margin: 0;
    padding-top: 5px;
}

.order-section form .row input,
.order-section form .row textarea {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.order-section form .row textarea {
    height: 20rem;
    resize: none;
}

form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
}

.order-section form .row .map {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .order form .row .input-box {
        width: 100%;
    }

    .order-section form .row {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 0;
    }

    .order-section form .row .input-box {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .order-section .icons-container {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}
</style>