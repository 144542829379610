<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section class="order-section">

        <div class="heading">
            <span>Restaurant Registration</span>
            <h3>Streamlined Ordering</h3>
        </div>

        <div class="icons-container">
            <!-- Deal with it later -->
            <div class="icons">
                <img src="../assets/images/user.png" alt="">
                <h3>{{ user.user_name }}</h3>
            </div>

            <div class="icons">
                <img src="../assets/images/email11.png" alt="">
                <h3>{{ user.email }}</h3>
            </div>

            <div class="icons">
                <img src="../assets/images/icon-3.png" alt="">
                <h3 v-if="restaurantObj.address"> {{ restaurantObj.address }}</h3>
                <h3 v-else>Pending ..</h3>
            </div>

        </div>

        <!-- booking form -->
        <form id="profileForm" @submit.prevent="handleSubmit" novalidate autocomplete="off">

            <div class="row">
                <div class="input-box">
                    <label for="uName">Restaurant Name</label>
                    <input type="text" name="uName" id="uName" v-model="restaurantObj.name">

                </div>
                <div class="input-box">
                    <label for="uPhone">Restaurant Address</label>
                    <input type="text" name="uPhone" id="uPhone" v-model="restaurantObj.address">

                </div>
            </div>

            <div class="row">
                <div class="input-box">
                    <label for="uName">Restaurant Phone</label>
                    <input type="number" placeholder="May used in payment" name="address" id="address" v-model="restaurantObj.phone">

                </div>
                <div class="input-box">
                    <label for="admin">Admin</label>
                    <input type="text" name="opassword" disabled id="opassword" v-model="user.user_name" />

                </div>

            </div>

            

            <input type="submit" value="Register Restaurant" class="btn">
        </form>

    </section>
</template>

<script>
import dayjs from'dayjs';
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
import { mapGetters, mapState } from 'vuex';
export default {
    name: "restaurantRegisration",

    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token"]),
    },

    data() {
        return {
            restaurantObj: { name: "", phone: "", address: "", user_name: '' }
        }
    },

    methods: {
        /**
         * @function getMatchUser takes @param {*} email to check if there is any existing user with the same email in the system 
        */
        async getMatchUser(email) {
            let data = await axios.get('/users/' + email);
            this.matchUser = data.data;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async handleSubmit(e) {


            e.preventDefault();
          

                await this.getMatchUser(this.restaurantObj.email);
                if (this.matchUser) {
                    this.restaurantObj.emailErr.push("Account already exist")
                    this.$refs.alert.showAlert('info', 'The email taken');
                }
                else {
                    
                    let data = {
                        name: this.restaurantObj.name,
                        phone: this.restaurantObj.phone,
                        address: this.restaurantObj.address,
                        user_id: this.user.user_id,
                        registered_when: dayjs(new Date(), 'DD-MMM-YYY HH:mm ')
                        
                    }
                    await axios.post("/restaurants/register", data, this.authObject)
                        .then(
                            (result) => {
                                console.log(result)
                                this.$router.push("/login");
                            }
                        )
                        .catch(
                            (err) => {
                                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                                    this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                                    localStorage.removeItem('access_token');
                                    localStorage.removeItem('user');
                                    this.$router.push('/login');
                                } else {
                                    console.error('An error occurred:', err.message);
                                }
                            }
                        )

                
            }


        }
    },

    components: {
        VueBasicAlert
    }

}
</script>

<style scoped>
.order-section {
    padding: 2rem 9%;
    width: 100%;
}

.order-section .icons-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.order-section .icons-container .icons {
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
}

.order-section .icons-container .icons img {
    height: 10rem;
}

.order-section .icons-container .icons h3 {
    font-size: 2rem;
    color: #130f40;
    margin-top: .5rem;
}

.order-section form {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: .5rem;
}

.order-section form .row {
    justify-content: space-between;
}

.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
}

.order-section form .row label {
    font-size: 1.7rem;
    color: #666;
}

.order-section form .row p {
    font-size: 1.5rem;
    position: absolute;
    color: rgb(243, 47, 47);
    margin: 0;
    padding-top: 5px;
}

.order-section form .row input,
.order-section form .row textarea {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.order-section form .row textarea {
    height: 20rem;
    resize: none;
}

form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
}

.order-section form .row .map {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .order form .row .input-box {
        width: 100%;
    }

    .order-section form .row {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 0;
    }

    .order-section form .row .input-box {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .order-section .icons-container {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}
</style>