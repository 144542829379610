<template>
    <div id="app">
        <div v-if="token">
            <div v-if="!user" class="red">
                <P>Loading ...</P>
            </div>
            <div v-else>
                <div v-if="user && user.role == 3">
                    <router-view></router-view>
                </div>
                <div v-else-if="user && user.role == 2">
                    <router-view></router-view>
                </div>
                <div v-else>
                    <NavBar />

                    <div class="auth-wrapper">
                        <div class="auth-inner">
                            <router-view></router-view>
                        </div>
                    </div>
                    <FooterComponent />
                </div>
            </div>

        </div>

        <div v-else>
            <NavBar />

            <div class="auth-wrapper">
                <div class="auth-inner">
                    <router-view></router-view>
                </div>
            </div>
            <FooterComponent />
        </div>
    </div>

</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterComponent from './components/FooterComponent.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
export default {
    name: 'App',
    components: {
        NavBar,
        FooterComponent
    },

    created() {
        this.getFoodsData()
        this.user
    },

    computed: {
        ...mapState('users', ["user", "token"])
    },

    methods: {
        ...mapActions('menu', ["getFoodsData"])
    }
}
</script>

<style>
@import "./assets/css/global_style.css";

.error {
    color: rgb(68, 245, 33);
    margin: 20px;
}
</style>
