<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section class="order-section">

        <div class="heading">
            <span>Update Restaurant Retails</span>
            <h3>enjoy your moment</h3>
        </div>

        <div class="icons-container">
            <!-- Deal with it later -->
            <div class="icons">
                <img src="../../assets/images/user11.png" alt="">
                <h3 v-if="restaurantObj.name">Registered by {{ restaurantObj.ownerName }}</h3>
                <h3 v-else>Registered by</h3>
            </div>

            <div class="icons">
                <img src="../../assets/images/email11.png" alt="">
                <h3 v-if="restaurantObj.ownerEmail">Email: {{ restaurantObj.ownerEmail }}</h3>
                <h3 v-else> Restaurant's Email</h3>
            </div>

            <div class="icons">
                <img src="../../assets/images/icon-3.png" alt="">
                <h3 v-if="restaurantObj.address"> Address: {{ restaurantObj.address }}</h3>
                <h3 v-else>Restaurant address</h3>
            </div>

        </div>

        <!-- booking form -->
        <form id="profileForm" @submit.prevent="handleSubmit" novalidate autocomplete="off">

            <div class="row">
                <div class="input-box">
                    <label for="uName">Restaurant Name</label>
                    <input type="text" name="uName" id="uName" v-model="restaurantObj.name">

                </div>
                <div class="input-box">
                    <label for="uPhone">Phone Number</label>
                    <input type="text" name="uPhone" id="uPhone" v-model="restaurantObj.phone">

                </div>
            </div>

            <div class="row">


                <div class="input-box">
                    <label for="uName">Address</label>
                    <input type="text" name="address" id="address" v-model="restaurantObj.address">

                </div>
                <div class="input-box">
                    <label for="oregisteredDate">registeredDate</label>
                    <input type="text" name="oregisteredDate" id="uBirth" v-model="restaurantObj.registeredDate"
                        disabled />

                </div>

            </div>


            <input type="submit" value="Update Profile" class="btn">
        </form>

    </section>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
import { mapGetters, mapState, mapActions } from 'vuex';
export default {
    name: "userProfile",

    created() {
        this.getResData()
    },
    computed: {
        ...mapGetters('users', ["authObject"]),
        ...mapState('users', ["user", "token", "restaurant"]),
    },

    data() {
        return {
            restaurantObj: { userId: "", name: "", phone: "", registeredDate: "", address: "", ownerName: "", ownerEmail: "", restId: "" }
        }
    },

    methods: {

        ...mapActions('users', ['logout']),

        async getResData() {

            if (this.user) {
                if (this.restaurant) {
                    let resData = JSON.parse(this.restaurant)
                    console.log(resData)
                    this.restaurantObj.name = resData.name
                    this.restaurantObj.phone = resData.phone
                    this.restaurantObj.registeredDate = resData.registered_when
                    this.restaurantObj.restId = resData.restaurant_id
                    this.restaurantObj.address = resData.address
                    // let owner = axios.get('/users/' + this.ownerId, this.authObject ).data
                    // let owner = JSON.parse(this.user)
                    this.restaurantObj.ownerEmail = this.user.email;
                    this.restaurantObj.ownerName = this.user.user_name;
                    this.restaurantObj.userId = this.user.user_id
                    console.log(this.restaurantObj.ownerEmail)
                }







            }
        },





        async handleSubmit(e) {
            e.preventDefault();  // Prevent the default form submission



            // If validation passes, proceed with the POST request
            let data = {
                name: this.restaurantObj.name,
                phone: parseInt(this.restaurantObj.phone),
                registeredDate: this.restaurantObj.registeredDate,
                address: this.restaurantObj.address,
                id: this.restaurantObj.restId
            };
            console.log(data);
            {

                try {
                    let result = await axios.post("/restaurants/update", data, this.authObject);
                    console.log(result);
                    this.$refs.alert.showAlert('success', 'Thank you, your information updated successfully');

                    axios.get('/restaurants/' + this.restaurantObj.userId, this.authObject)
                        .then(
                            (result) => {
                                this.restaurantObj.name = result.data.name
                                this.restaurantObj.phone = result.data.phone
                                this.restaurantObj.registeredDate = result.data.registered_when
                                this.restaurantObj.restId = result.data.restaurant_id
                                this.restaurantObj.address = result.data.address
                                localStorage.setItem('restaurant', JSON.stringify(result.data))
                                this.$router.push("/admin/dashboard/");
                            }
                        )
                } catch (err) {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        this.$refs.alert.showAlert('success', 'Sorry, your information was not updated. Your session expired');
                        this.$router.push('/login');
                        this.logout()
                    } else {
                        console.error('An error occurred:', err.message);
                    }
                }
            }
        }
    },

    components: {
        VueBasicAlert
    }

}
</script>

<style scoped>
.order-section {
    padding: 2rem 9%;
    width: 100%;
}

.order-section .icons-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.order-section .icons-container .icons {
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
}

.order-section .icons-container .icons img {
    height: 10rem;
}

.order-section .icons-container .icons h3 {
    font-size: 2rem;
    color: #130f40;
    margin-top: .5rem;
}

.order-section form {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: .5rem;
}

.order-section form .row {
    justify-content: space-between;
}

.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
}

.order-section form .row label {
    font-size: 1.7rem;
    color: #666;
}

.order-section form .row p {
    font-size: 1.5rem;
    position: absolute;
    color: rgb(243, 47, 47);
    margin: 0;
    padding-top: 5px;
}

.order-section form .row input,
.order-section form .row textarea {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.order-section form .row textarea {
    height: 20rem;
    resize: none;
}

form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
}

.order-section form .row .map {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .order form .row .input-box {
        width: 100%;
    }

    .order-section form .row {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 0;
    }

    .order-section form .row .input-box {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .order-section .icons-container {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}
</style>