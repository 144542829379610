<template>
  <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
  <section class="order-section">

    <div class="heading">
      <span>Register New Menu</span>
      <h3>enjoy your moment</h3>
    </div>


    <!-- booking form -->
    <form id="registerMenuForm" @submit="handleSubmit" novalidate autocomplete="off">

      <div class="row">
        <div class="input-box">
          <label for="uName">Food Name</label>
          <input type="text" name="uName" id="uName" v-model="menuObj.name">
          <p v-if="errorObj.nameErr.length > 0">{{ errorObj.nameErr[0] }}</p>
        </div>

        <div class="input-box">
          <label for="uName">Food Category</label>
          <select name="uCategory" id="coPhone" v-model="menuObj.category" placeholder="Chooche your food category"
            class="form-control text-center p-2">
            <option value="taco">Tacos General</option>
            <option value="burrito">Burrito Taste</option>
            <option value="dessert">Dessert</option>
            <option value="drinks">Drinks and Tea</option>
            <option value="nachos">Nachos</option>
            <option value="sides">Sides flavor</option>

          </select>
          <p v-if="errorObj.categoryErr.length > 0">{{ errorObj.categoryErr[0] }}</p>
        </div>

      </div>

      <div class="row">
        <div class="input-box">
          <label for="oPrice">Price</label>
          <input type="number" name="oPrice" id="oPrice" v-model="menuObj.price">
          <p v-if="errorObj.priceErr.length > 0">{{ errorObj.priceErr[0] }}</p>
        </div>
        <div class="input-box">
          <label for="oDiscount">Discount</label>
          <input type="number" name="oDiscount" id="oDiscount" v-model="menuObj.discount">
          <p v-if="errorObj.discountErr.length > 0">{{ errorObj.discoutnErr[0] }}</p>
        </div>
      </div>

      <div class="row">
        <div class="input-box">
          <label for="uDesc">Food Description</label>
          <input type="text" name="uDesc" id="uDesc" v-model="menuObj.desc">
          <p v-if="errorObj.descErr.length > 0">{{ errorObj.descErr[0] }}</p>
        </div>
        <div class="input-box">
          <label for="uName">Food Type</label>
          <select name="uType" id="coPhone" v-model="menuObj.type" placeholder="Chooche your food type"
            class="form-control text-center p-2">
            <option value="vegan">Vegan</option>
            <option value="meat">Meat</option>
            <option value="hydrates">Hydrates</option>
          </select>
        </div>
      </div>

      <div class="row">

        <FileUploading style=" width:99%; height: 450; margin: 10px; font-size: 12px;"
           @imageUploaded="handleImageUploaded" />

      </div>

      <input type="submit" value="Register Menu" class="btn">
    </form>

  </section>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
import FileUploading from '../../components/FileUploading.vue'
export default {
  name: "Table",


  data() {
    return {
      menuObj: { name: "", category: "", price: "", discount: "", desc: "", type: "" },
      errorObj: { nameErr: [], categoryErr: [], priceErr: [], descErr: [], typeErr: [], discountErr: [] },
      imagePath: '',
      imageURL: null,
    }
  },

  methods: {
    handleImageUploaded(path) {
      // Handling image data for further processing
      console.log(path + " image path");
      console.log("from image uploading");
      this.imagePath = path;
    },


    resetCheckErr: function () {
      this.errorObj.nameErr = [];
      this.errorObj.categoryErr = [];
      this.errorObj.priceErr = [];
      this.errorObj.descErr = [];
      this.errorObj.typeErr = [];
      this.errorObj.discountErr = [];
    },

    checkEmptyErr: function () {
      for (var typeErr in this.errorObj) {
        if (this.errorObj[typeErr].length != 0) {
          return false;
        }
      }
      return true;
    },



    checkForm: function () {
      this.resetCheckErr();

      // Name validate
      if (!this.menuObj.name) {
        this.errorObj.nameErr.push("Entering a name is required");
      }
      else {
        if (!/^[A-Za-z]+$/.test(this.menuObj.name.replace(/\s/g, ""))) {
          this.menuObj.nameErr.push('A name can only contain letters');
        }
      }

      // category validate
      if (!this.menuObj.category) {
        this.errorObj.categoryErr.push('Choosing category is must');
      }

      if (!this.menuObj.price) {
        this.errorObj.priceErr.push("You must provide menu price");
      }

      if (!this.menuObj.desc) {
        this.errorObj.descErr.push("Entering menu description");
      }

      if (!this.menuObj.type) {
        this.errorObj.typeErr.push("Select type");
      }


    },

    async handleSubmit(e) {
      e.preventDefault();
      this.checkForm();

      if (!this.checkEmptyErr()) {
        e.preventDefault();
      } else {
        e.preventDefault();

        let data = {
          food_name: this.menuObj.name,
          food_price: parseInt(this.menuObj.price),
          food_discount: parseInt(this.menuObj.discount),
          food_vote: 560,
          food_star: 4.5,
          food_desc: this.menuObj.desc,
          food_category: this.menuObj.category,
          food_type: this.menuObj.type,
          food_src: this.imagePath,
          food_status: "New Dishes",
          restaurant_id: (JSON.parse(localStorage.getItem("restaurant"))).restaurant_id
        }

       await axios.post("/food/register", data);
        console.log(data.food_src);

        this.$refs.alert.showAlert('success', 'Congratulations,', 'Menu registered Successfully !')
        document.getElementById("registerMenuForm").reset();
      }
    },

  },

  components: {
    VueBasicAlert,
    FileUploading
  }

}
</script>

<style scoped>
.admin-container {
  background-color: #fff;
  height: 100vh;
  padding: 2rem 9%;
  font-size: 16px;
  width: 100%;
}

.order-section {
  padding: 2rem 9%;
  width: 100%;
}

.order-section .icons-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.order-section .icons-container .icons {
  border-radius: .5rem;
  padding: 2rem;
  text-align: center;
  background: #f7f7f7;
}

.order-section .icons-container .icons img {
  height: 10rem;
}

.order-section .icons-container .icons h3 {
  font-size: 2rem;
  color: #130f40;
  margin-top: .5rem;
}

.order-section form {
  background: #f7f7f7;
  padding: 2rem;
  border-radius: .5rem;
}

.order-section form .row {
  justify-content: space-between;
}

.order-section form .row .input-box {
  width: 49%;
  padding: 1.8rem 0;
}

.order-section form .row label {
  font-size: 1.7rem;
  color: #666;
}

.order-section form .row p {
  font-size: 1.5rem;
  position: absolute;
  color: rgb(243, 47, 47);
  margin: 0;
  padding-top: 5px;
}

.order-section form .row input,
.order-section form .row select,
.order-section form .row textarea {
  width: 100%;
  margin-top: .5rem;
  padding: 1rem 1.2rem;
  width: 100%;
  border-radius: .5rem;
  font-size: 1.6rem;
  text-transform: none;
  color: #130f40;
}

.order-section form .row textarea {
  height: 20rem;
  resize: none;
}

.order-section form .row .map {
  height: 100%;
  width: 100%;
  border-radius: .5rem;
}

@media (max-width: 768px) {

  .order-section form .row select,
  .order form .row .input-box {
    width: 100%;
  }

  .order-section form .row {
    display: block;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  .order-section form .row select,
  .order-section form .row .input-box {
    width: 100%;
  }

}

@media (max-width: 576px) {
  .order-section .icons-container {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
}
</style>
