import Axios from 'axios'
import { store } from '..'

const errorHandling = {
  unauthorzied: () => {
    store.dispatch('/logout')
  }
}

function checkErrors(error) {
  if (error.response.status === 401) {
    errorHandling.unauthorzied();
  }
}

const state = {
  allFoods: [],
  product: null,
  cart:[]
}
const getters = {
  productList(state) {
    return state.products
  },
  cartItemCount: (state) => {
    return state.cart.length;
  },
}
const mutations = {
  setFoodsData(state, payload) {
    state.allFoods = payload;
  },
  ADD_TO_CART(state, item){
    state.cart.push(item)
  }
}
const actions = {
  async getFoodsData(context) {
    await Axios.get('/foods')
      .then(function (response) {
        context.commit("setFoodsData", response.data);
      })
      .catch(function (error) {
        console.log(error);
        checkErrors(error)
      });
  }
}

export default {
  state, getters, mutations, actions, namespaced: true, checkErrors
}