<template>
    <div class="admin-container">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="ms-auto">Total Orders</h1>
            </div>
            <div class="col text-right">
                <button class="btn btn-primary btn-small mx-2 " @click="Download">Get Weekly Report</button>
                <button class="btn btn-primary btn-small " @click="downloadAccepted">Get Weekly Accepted Orders</button>
            </div>
        </div>
        <div class="table-responsive" id="toPint">
            <!-- PROJECT TABLE -->
            <table class="table colored-header datatable project-list table-hover table-striped">
                <thead class="table-primary">
                    <tr>
                        <th scope="col" >Bill Id</th>
                        <th scope="col" >User name</th>
                        <th scope="col" >Phone</th>
                        <th scope="col" >Address</th>
                        <th scope="col" >When</th>
                        <th scope="col" >Paid</th>
                        <th scope="col" >Total</th>
                        <th scope="col" >Status</th>
                        <th scope="col" >Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(b) in filterBills.slice().reverse()" :key="b.bill_id">
                        <td scope="col" > <button title="Preview Order" @click="sendBillId(b.bill_id)" class="action-btn">{{ b.bill_id }}</button> </td>
                        <td>{{ knowUser(b.user_id )}}</td>
                        <td>{{ b.bill_phone }}</td>
                        <td>{{ b.bill_address }}</td>
                        <td>{{ b.bill_when }}</td>
                        <td>{{ b.bill_paid }}</td>
                        <td>Tsh{{ b.bill_total }}</td>
                        <td>{{ avaiableStatus[b.bill_status] }}</td>
                        <td>
                            <button v-if="b.bill_status < 5" class="action-btn" @click="nextStatusBtn(b.bill_id)">
                                {{ avaiableStatus[b.bill_status + 1] }}
                            </button>

                            <button v-if="b.bill_status == 1" class="cancel-btn" @click="cancelBtn(b.bill_id)">
                                Cancel
                            </button>

                            <button v-else-if="b.bill_status == 5 && b.bill_paid != 'true'" class="paid-btn"
                                @click="paidBtn(b.bill_id)">
                                Paid
                            </button>

                            <button v-else-if="b.bill_status == 5 && b.bill_paid == 'true'" class="action-btn"
                                @click="nextStatusBtn(b.bill_id)">
                                {{ avaiableStatus[b.bill_status + 1] }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <OrderDetails v-if="showOrderDetails" :bill="sendId" >
            <button class="btn" @click="closeView">X</button>   
        </OrderDetails>
    </div>
</template>
<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import OrderDetails from "@/components/OrderDetails.vue";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

export default {
    name: 'Home',
    components: {
        OrderDetails
    },

    data() {
        return {
            avaiableStatus: ["cancel", "confirmed", "preparing", "checking", "delivering", "delivered", "completed"],
            allBills: [],
            showOrderDetails: false,
            sendId: undefined,
            interval: "",
            users: []
        }
    },

    created: function(){
        this.getAllBills();
        this.fetchUsers();
    },

    mounted: function () {
        this.autoUpdate();
    },

    beforeUnmount() {
        clearInterval(this.interval)
    },

    computed: {
        ...mapState("users", ["admin", "user", "token", "restaurant"]),
        ...mapState('menu', ["allFoods"]),
        ...mapGetters('users', ["authObject"]),

        filterBills: function () {
            return this.allBills.filter((b) => b.bill_status < 7 && b.bill_status > 0);
        },
    },

    methods: {
        ...mapMutations('users', ["SET_ADMIN"]),
        ...mapActions('users', ["logout"]),

        async getAllBills() {
            console.log("the getAllBills function start runging")
            if (this.token || this.admin) {
                try {
                    let restaurant_id = (JSON.parse(localStorage.getItem('restaurant'))).restaurant_id
                    this.allBills = (await axios.get('admin/billstatus/restaurant/'+ restaurant_id, this.authObject)).data;
                }

                catch (err) {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        this.$router.push('/login');
                        this.logout()
                    } else {
                        console.error('An error occurred:', err.message);
                    }
                }
            }
        },

        sendBillId: function (id) {
            this.sendId = id
            this.showOrderDetails = !this.showOrderDetails;
        },

        closeView: function () {
            this.showOrderDetails = !this.showOrderDetails;
        },

        handleLogout: function () {
            this.$router.push('/login')
            this.logout();

        },

        async nextStatusBtn(id) {
            await axios.get('admin/billstatus/' + id, this.authObject);
            this.getAllBills();
        },

        async paidBtn(id) {
            await axios.get('admin/billstatus/paid/' + id, this.authObject);
            this.getAllBills();
        },

        async cancelBtn(id) {
            await axios.get('admin/billstatus/cancel/' + id, this.authObject);
            this.getAllBills();
        },

        autoUpdate: function () {
            this.interval = setInterval(function () {
                this.getAllBills();
            }.bind(this), 1000);

        },
        async fetchUsers() {
            await axios.get('/users/').then(
                (result) => {
                    console.log(result.data)
                    this.users = result.data
                    return result.data.user_name
                }
            )

        },
        knowUser(id) {
            const userFound = this.users.find(u => u.user_id === id);
            if (userFound) {
                return userFound.user_name;
            } else {
                return 'User not found';
            }
        },
        async Download() {
            const doc = new jsPDF();
            const today = new Date();
            const formattedDate = ("0" + today.getDate()).slice(-2) + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + today.getFullYear();
            const resData = await JSON.parse(localStorage.getItem('restaurant'))
            console.log(resData)
            doc.text(`Restaurant name: ${resData.name}`, 10, 10);
            doc.text(`Date: ${formattedDate}`, 155, 10)
            doc.text(`Restaurant Address: ${resData.address}`, 10, 18);
            doc.text('Total Orders Report', 10, 26);
            doc.line(0, 31, 400, 31);
            autoTable(doc, {
                head: [['Customer Name', 'Phone', 'Address', 'Status', 'Total (Tsh)', 'Paid', 'Billed date']],
                margin: { top: 35 },
                body: this.filterBills.slice().reverse().map(b => [
                    this.knowUser(b.user_id),
                    b.bill_phone,
                    b.bill_address,
                    this.avaiableStatus[b.bill_status],
                    b.bill_total,
                    b.bill_paid,
                    b.bill_when
                ])
            });
            var pageHeight = doc.internal.pageSize.getHeight();
            var footerMargin = 10; // Adjust the margin as needed
            var footerText = "Generated by Nitume Plartform   |  Page  1 "; 
            var footerX = doc.internal.pageSize.getWidth() - footerMargin - 60;
            //the lower the minus the right it become
            function writeNameWithFontSize(text, fontSize, x, y) {
                doc.setFontSize(fontSize);
                doc.text(text, x, y);
            }

            //doc.text(footerText, footerX, pageHeight - footerMargin);
            writeNameWithFontSize(footerText, 9, footerX ,pageHeight - footerMargin );

            doc.save('total-orders.pdf')
        },
        async downloadAccepted() {
            const doc = new jsPDF();
            const today = new Date();
            const formattedDate = ("0" + today.getDate()).slice(-2) + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + today.getFullYear();
            const filteredBills = this.filterBills.filter(bill => bill.user_confirm === 1);
            const resData = await JSON.parse(localStorage.getItem('restaurant'))
            console.log(resData)
            doc.text(`Restaurant name: ${resData.name}`, 10, 10);
            doc.text(`Restaurant Address: ${resData.address}`, 10, 18);
            doc.text('Completed and Accepted Orders Report.', 10, 26);
            doc.text(`Date: ${formattedDate}`, 155, 10)
            doc.line(0, 31, 400, 31);
            autoTable(doc, {
                head: [['Customer Name', 'Phone', 'Address', 'Total (Tsh)', 'Billed date', 'Delivered By']],
                margin: { top: 35 },
                body: filteredBills.slice().reverse().map(b => [
                    this.knowUser(b.user_id),
                    b.bill_phone,
                    b.bill_address,
                    b.bill_total,
                    b.bill_when,
                    "Alex"
                ])
            });
            var pageHeight = doc.internal.pageSize.getHeight();
            var footerMargin = 10; // Adjust the margin as needed
            var footerText = "Generated by Nitume Plartform   |  Page  1 "; 
            var footerX = doc.internal.pageSize.getWidth() - footerMargin - 60;
            function writeNameWithFontSize(text, fontSize, x, y) {
                doc.setFontSize(fontSize);
                doc.text(text, x, y);
            }

            //doc.text(footerText, footerX, pageHeight - footerMargin);
            writeNameWithFontSize(footerText, 9, footerX ,pageHeight - footerMargin );
            doc.save('accepted-orders.pdf')
        }

    },
}
</script>

<style scoped>
.admin-container {
    background-color: #fff;
    height: 100vh;
    padding: 2rem 9%;
    font-size: 16px;
    width: 100%;
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
    border-radius: 5px
}

.cancel-btn,
.paid-btn {
    background-color: red;
    border-radius: 5px;
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>
